.portfolio__filter-container {
  z-index: 1;
  background-color: white;
  border: 1px solid var(--background-color-6);
  width: 18em;
  padding: 0 1em;
  border-radius: 0 !important;
}

.portfolio__filter-title {
  padding: 0 !important;
}

.portfolio__filter-title h6 {
  color: var(--font-color-4);
  width: 100%;
  margin: 0;
}

.portfolio__filter-options {
  display: flex;
  flex-direction: column;
  border-top: 3px solid var(--font-color-4) !important;
  padding: 0 !important;
}

.portfolio__filter-options img {
  margin: 1em;
  width: 100%;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio__filter-option {
  margin-bottom: 1em;
  padding-left: 0.5em;
  color: var(--font-color-2);
  cursor: pointer;
}

.portfolio__filter-option sup {
  right: -3px;
}

.portfolio__filter-option:hover {
  font-weight: 500;
}

.red {
  border-left: 4px solid var(--font-color-3);
}
.blue {
  border-left: 4px solid var(--font-color-4);
}
.yellow {
  border-left: 4px solid var(--font-color-5);
}

.gray {
  border-left: 4px solid var(--font-color-1);
}

@media (max-width: 1000px) {
  .portfolio__filter-container {
    width: 100%;
  }
}
