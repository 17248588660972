.portfolio {
  height: 100%;
}

.portfolio__filter {
  position: relative;
  height: 4em;
}

.portfolio__grid {
  position: relative;
  z-index: 0;
  height: 100%;
}

.portfolio__grid span {
  padding: 0 0.25em;
}

.portfolio__grid a:hover {
  text-decoration: none;
}

.project {
  margin-bottom: 3em;
  cursor: pointer;
}

.project:hover {
  transform: scale(1.03);
  transition: transform 0.2s;
}

.project__image {
  width: 100%;
  height: 100%;
  margin-bottom: 1em;
  object-fit: contain;
}

.project__text {
  padding: 0.5em;
}

.project__name {
  color: #545454;
  font-weight: bold;
}
.project__year {
  color: #898989;
  font-weight: 400;
}

/*Display a different color depending on the project category*/
.blue {
  border-left: 4px solid var(--background-color-3);
}
.red {
  border-left: 4px solid var(--background-color-2);
}
.yellow {
  border-left: 4px solid var(--background-color-4);
}

.project__image-container {
  position: relative;
}

.project__image-container:hover .project__view-more h6 {
  color: var(--background-color-3);
  font-weight: 500;
}
.project__image-container:hover svg {
  color: var(--background-color-3);
}

.project__view-more {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: black;
  font-size: 0.9rem;
  right: 0;
  bottom: 1.1em;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.95);
}

.project__view-more h6 {
  margin: 0 0.5em 0 0;
  font-weight: 400;
}

.project__arrow {
  width: 1em;
  margin-left: 0.5em;
}
