.documents__accordion-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.5em;
}

.documents__accordion-item a {
  color: var(--font-color-1) !important;
}

.documents__accordion-item a:hover {
  color: var(--font-color-3) !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.documents__icon {
  margin-right: 0.5em;
  width: 1.5em;
  cursor: pointer;
}

.documents__accordion-container-sub {
  padding-left: 20px;
}