.side-drawer {
  height: calc(100% - var(--header-height));
  background-color: rgb(250, 250, 250);
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 40px;
}

.language-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.language-buttons h1 {
  font-size: 1.7em;
  font-weight: 100;
  margin: 0;
  padding: 0;
  margin-block: 0;
  margin-inline: 0;
  line-height: unset;
}

.language-button {
  border: none;
  width: 3.5em;
  height: 2.5em;
  border-radius: 3px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.side-drawer li {
  margin: 0.5rem 0;
}

.side-drawer a,
.link {
  color: var(--font-color-1);
  text-decoration: none;
  font-size: var(--font-size-2);
}

.side-drawer a:hover,
.side-drawer a:active,
.side-drawer .active {
  color: var(--font-color-4);
}

.side-drawer li:last-child a {
  color: var(--font-color-3);
  font-weight: bolder;
}

.button {
  background-color: transparent;
  border: 0;
  color: var(--font-color-1);
  text-decoration: none;
  font-size: var(--font-size-2);
}

.dropdown-content {
  display: none;
  width: inherit;
  padding: 0.5em;
}

.dropdown-content a {
  margin-top: 1em;
}

li:hover .dropdown-content {
  display: flex;
  flex-direction: column;
}

.side-drawer__collapsible-menu.open {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.side-drawer__collapsible-menu.hidden {
  display: none;
}

.side-drawer__collapsible-menu a {
  margin-top: 0.5rem;
  font-size: var(--font-size-1);
}

@media (min-width: 1201px) {
  .side-drawer {
    display: none;
  }
}
