.toolbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--background-color-1);
  height: var(--header-height);
  z-index: 200;
  left: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.toolbar__logo {
  height: 100%;
  display: flex;
}

.toolbar__logo a {
  height: 100%;
  width: fit-content;
}

.toolbar__logo img {
  height: 100%;
  width: auto;
  padding: 1.7em 0;
}

.toolbar__logo h6 {
  display: none;
}

.spacer {
  display: none;
  flex: 1;
}

.toolbar__side-options {
  display: flex;
  align-items: center;
}

.toolbar__search-icon {
  width: 1.5em;
  margin-left: 1em;
}

.toolbar__navigation-items a {
  color: var(--font-color-1);
  font-size: var(--font-size-2);
  text-decoration: none;
}

.toolbar__navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar__navigation-items li {
  padding: 0 0.5rem;
  width: fit-content;
}

.toolbar__navigation-items a:hover,
.toolbar__navigation-items a:active,
.toolbar__navigation-items .active {
  color: var(--font-color-4);
}

.toolbar__navigation-items li:last-child a {
  color: var(--font-color-3);
  font-weight: bolder;
}

.toolbar__toggle-button {
  margin-left: 2em;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 1em 1.5em;
}

.dropdown-content a {
  margin-top: 0.5em;
  font-size: var(--font-size-1);
}

li:hover .dropdown-content {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1200px) {
  .toolbar__logo img.icon {
    content: url("../../resources/media/logos/Globaleda_logo_icon.svg");
  }

  .toolbar__navigation-items {
    display: none;
  }

  .toolbar__logo h6 {
    display: flex;
    align-items: center;
    margin: 0 0 0 0.5em;
    font-weight: bolder;
    font-size: var(--font-size-2);
    color: var(--font-color-4);
  }

  .spacer {
    display: flex;
  }
}

@media (min-width: 1201px) {
  .toolbar__toggle-button {
    display: none;
  }
}
