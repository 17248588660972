.waves {
  margin-top: 3em;
}

.title_row {
  display: flex;
  justify-content: space-between;
}

.title_row h2 {
  color: var(--font-color-4);
  max-width: 450px;
  font-weight: 700;
  text-transform: uppercase;
  align-self: center;
}

.title_row img {
  max-width: 250px;
}

.mensagem p {
  color: var(--font-color-4);
  text-align: justify;
}

.first-line {
  margin-top: 2em;
}

.signature {
  text-align: center !important;
}
