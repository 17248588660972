.cols {
    display: grid;
    grid-template-columns: 0.6fr 1.4fr;
}

.result_div {
    display: flex;
    flex-direction: column;
    margin-top: 3em;
}

.result_link {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    cursor: pointer;
}

a.result_link {
    color: var(--font-color-4) !important;
    font-size: 1.25rem;
    font-weight: bold;
}

a.result_link :hover {
    color: var(--font-color-4) !important;
    text-decoration: none !important;
    cursor: pointer !important;
}

.documents__icon {
    margin-right: 0.5em;
    width: 1.5em;
    cursor: pointer;
}

.result_type {
    color: var(--font-color-2);
    margin-bottom: 0.5em;
}

.result_summary {
    color: var(--font-color-1);
}

.no_results {
    color: var(--font-color-1);
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 4rem;
}

@media (max-width: 768px) {
    .cols {
        grid-template-columns: 1fr;
    }

}