.language-button__container {
  display: flex;
  flex-direction: row;
}

.language-button {
  border: none;
  width: 1.5em;
  border-radius: 3px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--font-color-1);
}

.language-button img {
  width: 100%;
}

.language-button-active {
  font-weight: 900;
}

@media (max-width: 1200px) {
  .mobile-hide {
    display: none;
  }
}
