.carousel {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.carousel li {
  position: relative !important;
  top: 3em !important;
  height: 6px !important;
  background-color: var(--background-color-2) !important;
  z-index: 1;
}

.carousel ol {
  z-index: 1;
}

.carousel a {
  width: 1em;
}

.carousel__image {
  height: 35em;
  width: 35em;
  object-fit: contain;
}

.carousel__image-tag,
.carousel__image-discount {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
  background-color: var(--background-color-2);
}

.carousel__image-tag {
  height: 3em;
  width: 10em;
  bottom: 1em;
  z-index: 1;
}

.carousel__image-discount {
  height: 5em;
  width: 6em;
  right: 1em;
  top: 3em;
  z-index: -1;
}

.carousel__image-tag h3,
.carousel__image-discount h3 {
  font-weight: 600;
  margin: 0;
}

.carousel__thumbnails img {
  height: 8em;
  padding: 1em;
  margin: 0 1em;
  background-color: rgb(241, 241, 241);
  cursor: pointer;
}

.carousel__thumbnails img:hover {
  box-shadow: 1px 1px 9px 1px #b6b6b6;
}

.carousel__thumbnails--selected {
  box-shadow: 1px 1px 5px 1px #b6b6b6;
}

@media (max-width: 1200px) {
  .carousel a,
  .carousel__thumbnails {
    display: none;
  }

  .carousel__thumbnails {
    margin-top: 2em;
  }
}

@media (max-width: 575px) {
  .carousel__image {
    width: 100%;
    height: 100%;
  }

  .carousel__image-tag {
    bottom: 0.9em;
    left: 0;
  }

  .carousel__image-discount {
    z-index: 1;
    right: 0;
    top: 1.1em;
  }
  .carousel__thumbnails img {
    margin: 0 0.5em;
  }
}
