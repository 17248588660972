.documents {
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  top: 4em;
  width: 60em;
}

/* Hide Scrollbar */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.documents__content {
  margin: 8em 0 5em 0;
  padding: 0 1em;
}

.complaint-book {
  text-align: center;
}

.complaint-book a {
  color: var(--font-color-1);
  text-decoration: none;
}

.complaint-book a:hover {
  color: var(--font-color-3);
}

.complaint-book img {
  margin: 2em auto;
  width: 10em;
  display: block;
}

@media (max-width: 575px) {
  .documents__content {
    margin-top: 6em;
  }
}

@media (max-width: 1200px) {
  .documents {
    width: 90vw;
  }
}
