.product {
  grid-column: span 1;
  grid-row: span 1;
  justify-self: center;
  text-align: center;
}

.product:hover .product__image-container {
  transform: scale(1.05);
  transition: transform 0.2s;
}

.product__image-container {
  position: relative;
  margin-bottom: 1em;
}

.product__image {
  height: 14em;
  padding-top: 6px;
  object-fit: contain;
}

.product__name {
  color: var(--font-color-1);
}

.product__discount {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
}

.product__discount {
  background-color: var(--background-color-2);
}

.product__discount {
  right: 2em;
  top: 1.5em;
  width: 3em;
  height: 2.5em;
}

.product__discount h6 {
  margin: 0;
}

.product__prices {
  display: flex;
  justify-content: space-between;
  width: 7em;
}

.product__price-now h6 {
  color: var(--font-color-3);
  margin: 0;
  font-weight: bold;
}

.product__price-before h6 {
  color: grey;
  margin: 0;
  margin-left: 1em;
}

.product__value-before {
  text-decoration: line-through;
}

.product__normal-price {
  color: var(--font-color-1);
  font-weight: bold;
  margin-top: 0.5em;
}
