.success-popup {
  display: flex;
  height: 100%;
  min-height: 20em;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.success-popup h1 {
  font-weight: 700;
  color: var(--font-color-4);
}

.success-popup p {
  margin-bottom: 3em;
}
