.partner {
  margin: 0 1em;
  color: var(--font-color-2);
}

.partner__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: min-content 1fr;
  column-gap: 4em;
  margin: 0 4em;
}

.partner__image {
  width: 100%;
  height: 15em;
  object-fit: contain;
  margin: auto;
  grid-column-start: span 1;
  grid-row-start: span 2;
}

.partner__title {
  /* margin-top: 2em; */
  grid-row-start: span 1;
  height: min-content;
}

.partner__title h2 {
  color: var(--font-color-1);
  font-weight: bold;
}

.partner__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2em;
  grid-row-start: span 2;
}

.partner__long-description {
  margin: 4em;
}

.partner__projects h2 {
  color: var(--font-color-1);
  margin: 1em 0.5em;
  font-weight: bold;
}

.partner__projects a:hover {
  text-decoration: none;
}

.project {
  margin-bottom: 3em;
  cursor: pointer;
}

.project:hover {
  transform: scale(1.03);
  transition: transform 0.2s;
}
.project:hover .project__name {
  font-weight: 600;
}

.project__image {
  width: 100%;
  height: 100%;
  margin-bottom: 1em;
}

.project__text {
  padding: 0.5em;
}

.project__name {
  color: #545454;
  font-weight: bold;
}
.project__year {
  color: #898989;
  font-weight: 400;
}

/*Display a different color depending on the project category*/
.blue {
  border-left: 4px solid var(--background-color-3);
}
.red {
  border-left: 4px solid var(--background-color-2);
}
.yellow {
  border-left: 4px solid var(--background-color-4);
}

.project__image-container {
  position: relative;
}

.project__image-container:hover .project__view-more h6 {
  color: var(--background-color-3);
  font-weight: 500;
}
.project__image-container:hover svg {
  color: var(--background-color-3);
}

.project__view-more {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: black;
  font-size: 0.9rem;
  right: 0;
  bottom: 1.1em;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.95);
}

.project__view-more h6 {
  margin: 0 0.5em 0 0;
  font-weight: 400;
}

.project__arrow {
  width: 1em;
  margin-left: 0.5em;
}

.partner__gallery-image {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.partner__button-container {
  display: flex;
  justify-content: center;
  margin: 3em 0;
}

.partner__button-container a {
  text-decoration: none !important;
}

.partner__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* TABLET */
@media (max-width: 1200px) {
  .partner {
    margin: 0;
  }

  .partner__grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: min-content min-content 1fr;
    margin: 1em;
  }

  .partner__image {
    width: 100%;
    height: 18em;
    margin: 2em auto;
    grid-row: 2/3;
  }

  .partner__title {
    margin-bottom: 1em;
  }

  .partner__content {
    grid-row-start: span 1;
  }

  .partner__long-description {
    margin: 4em 1em;
  }

  .partner__projects {
    margin: 0 1em;
  }
}

/* MOBILE */
@media (max-width: 575px) {
  .partner__image {
    width: 100%;
    height: 8em;
    object-fit: contain;
    margin: 0 auto;
  }
}
