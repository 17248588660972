.section {
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
}

/*FIRST SECTION*/
.first-section {
  /* padding-top: 2em; */
  height: auto;
  display: grid;
}

.first-section__header-text {
  padding: 0 1em;
  color: var(--font-color-1);
}

.first-section__header-text div > p > a {
  color: var(--font-color-1)!important;
}

.first-section__header-text div > p > a:hover {
  color: var(--font-color-3)!important;
  text-decoration: none!important;
  cursor: pointer!important;
}

.first-section__header-text h2 {
  font-weight: bold;
}

.first-section__cards {
  display: flex;
  margin-top: 2em;
}

.first-section__card {
  display: flex;
  flex-direction: column;
  float: left;
  width: 33.33%;
  height: auto;
}

.first-section__red-card {
  background-color: var(--background-color-2);
  z-index: 2;
}

.first-section__yellow-card {
  background-color: var(--background-color-4);
  z-index: 1;
}

.first-section__blue-card {
  background-color: var(--background-color-3);
  z-index: 0;
}

.first-section__logo-container {
  height: 23em;
  width: auto;
  padding: 2em 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
}

.first-section__logo-container img {
  width: 15em;
}

.first-section__card-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  text-align: center;
  color: white;
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-left: 0;
  height: 100%;
  margin-bottom: 4rem;
}

.first-section__yellow-card .first-section__card-list a,
.first-section__yellow-card .card__title {
  color: var(--font-color-1);
}

.first-section__card-list li {
  margin: 1em 0;
}

.first-section__card-list a {
  text-decoration: none !important;
  color: white;
}

/*SECOND, THIRD AND FOURTH SECTION COMMON STYLING*/
.second-section,
.third-section,
.fourth-section {
  flex-direction: row;
}

.second-section__image,
.third-section__image,
.fourth-section__image {
  width: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: ellipse(100% 76% at 0% 74%);
}

.second-section__text,
.third-section__text,
.fourth-section__text {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  padding: 4rem 6rem 2rem 6rem;
}

/*SECOND SECTION*/
.second-section {
  background: rgb(84, 85, 84);
  background: linear-gradient(
    0deg,
    rgba(84, 85, 84, 1) 0%,
    rgba(137, 137, 137, 1) 100%
  );
}

.card__title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
  margin-top: 4rem;
}

.second-section__image {
  background-image: url(../../resources/media/images/services/team-work.jpg);
}

.second-section__text h6 {
  margin: 1em 0 2em 0;
  font-weight: bold;
}

.second-section__text h2 {
  font-weight: bold;
}

.second-section__text a {
  color: white;
  border-bottom: white 1px solid;
}

.second-section__text a:hover {
  color: white;
  text-decoration: none;
}

.second-section__arrow-icon {
  width: 0.9em;
  margin-left: 0.3em;
}

/*THIRD SECTION*/
.third-section {
  height: fit-content;
  background: rgb(84, 85, 84);
  background: linear-gradient(
    0deg,
    rgba(84, 85, 84, 1) 0%,
    rgba(137, 137, 137, 1) 100%
  );
}

.third-section__image {
  background-image: url(../../resources/media/images/services/computers.png);
}

.third-section__text img {
  margin: 4em 2em 0 0;
  width: 30em;
}

.third-section__text h2 {
  font-weight: bold;
}

.third-section__text p {
  margin-top: 1em;
}

/*FOURTH SECTION*/
.fourth-section {
  background-color: var(--background-color-3);
}

.fourth-section__image {
  background-image: url(../../resources/media/images/services/code.png);
}
.fourth-section__text h1 {
  margin-bottom: 1em;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .card__title {
    margin-top: 0;
    padding: 1em;
  }
  .first-section__cards {
    flex-direction: column;
  }

  .first-section__card {
    flex-direction: row;
    width: 100%;
  }

  .first-section__card-list {
    width: 100%;
    font-size: 0.8em;
    margin: 4rem 0;
  }

  .second-section,
  .third-section,
  .fourth-section {
    height: unset;
  }

  .second-section__image,
  .third-section__image,
  .fourth-section__image {
    display: none;
  }

  .second-section__text,
  .third-section__text,
  .fourth-section__text {
    width: 100%;
    padding: 2em 1em;
  }

  .third-section__text img {
    margin-top: 3em;
  }
}

@media (max-width: 575px) {
  .section {
    height: unset;
    text-align: center;
  }

  .first-section__card {
    flex-direction: column;
  }

  .first-section__logo-container {
    width: 100%;
    padding: 3em;
  }

  .third-section__text img {
    width: 100%;
  }
}
