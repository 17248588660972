.partnerships {
  color: var(--font-color-1);
}

.partnerships__intro-text {
  padding: 0 1em;
}

.partnerships__intro-text h3 {
  font-weight: bold;
}

.partnerships__logos {
  display: grid;
  grid-gap: 1.5em;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  margin-bottom: 2em;
}

.partnerships__logos a {
  position: relative;
  padding: 2.5em 2.5em 4em 2.5em;
  text-align: center;
  background-color: rgb(245, 245, 245);
}

.partnerships__logo-img {
  width: 100%;
  height: 5em;
  object-fit: contain;
}

.partnerships__logos a:hover {
  transform: scale(1.05);
  transition: transform 0.2s;
}
.partnerships__logos a:hover button {
  color: var(--background-color-3);
  font-weight: 500;
}

.partnerships__button {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  border: none;
  padding: 0.5em 1em;
}
.partnerships__logos button:focus {
  outline: none;
}

button .partnerships__arrow {
  width: 1em;
  height: 1em;
  margin-left: 0.5em;
}

.partnerships__advantages {
  display: flex;
  padding: 0 1em;
}

.partnerships__image {
  height: 70vh;
  width: 50%;
  background-color: red;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: ellipse(100% 76% at 0% 74%);
}

.partnerships__text {
  width: 50%;
  margin: auto;
  padding: 0 2em;
}

.partnerships__text h2 {
  margin-bottom: 0.5em;
  font-weight: bold;
}

.partnerships__button svg {
  margin-left: 0.5em;
}

@media (max-width: 1000px) {
  .partnerships__logos {
    grid-template-columns: repeat(3, 1fr);
  }

  .partnerships__image {
    display: none;
  }

  .partnerships__text {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 575px) {
  .partnerships__logos {
    grid-template-columns: auto;
  }

  .partnerships__logo-img {
    width: 6em;
  }
}
