.timeline {
  position: relative;
  text-align: center;
}

.timeline h1 {
  color: var(--font-color-1);
  font-size: 2rem !important;
  font-weight: bold;
}

.timeline h2 {
  font-size: 1.25rem !important;
  font-weight: bold;
}

.timeline h3 {
  font-size: 1rem !important;
}

.timeline h2 {
  color: var(--font-color-1);
}

.timeline__chrono-container {
  margin: 2em auto 0 auto;
}

.timeline__chrono-container div[class*="e1gmwefz1"] {
  position: absolute;
  left: 0;
  right: 0;
}

.timeline ul {
  background-color: white;
}

.timeline ul li button svg,
.timeline ul li button {
  display: none;
}

.timeline ul li button[title~="Play"] {
  display: block;
  width: 3.5em;
  height: 3.5em;
  margin-right: 0.9em;
  background-image: url("../../resources/media/icons/homepage/play.svg");
}

.timeline__chrono-container section {
  filter: none;
  color: var(--font-color-1);
}
.timeline__chrono-container div[class*="e1gmwefz1"] {
  filter: none;
}

.timeline__card {
  width: 80%;
  /* display: flow-root;
  height: 300px; */
}

.timeline__card h3 {
  color: var(--font-color-4);
}

.timeline__card h2 {
  margin-top: 1em;
}

.timeline__card div {
  font-size: 1rem;
}

.timeline__card p {
  font-size: 1rem;
}

.timeline__card img {
  height: 22em;
  width: 100%;
  object-fit: contain;
}

.timeline__card p {
  font-size: 1rem;
  line-height: 1.5;
}

.timeline__logo {
  width: 26em;
  margin-left: 1.5em;
}

.timeline__waves {
  margin-top: 3em;
}

.timeline__eda {
  font-weight: bold;
  position: absolute;
  color: white;
  bottom: 0.5em;
  left: 6em;
}

/* MOBILE */
@media (max-width: 575px) {
  .timeline__chrono-container
    div[class*="css-16u30nb-TimelineControlContainer"] {
    justify-content: flex-start;
    left: -0.3em;
  }

  .timeline h2 {
    text-align: left;
  }

  .timeline__card img {
    height: 20em;
    margin-bottom: 1em;
  }

  .timeline__logo {
    width: 20em;
    margin-left: 0;
    padding: 0 1em;
  }

  .timeline__eda {
    display: none;
  }

  .timeline__logo {
    margin-bottom: 2em;
  }

  .timeline__waves {
    display: none;
  }
}
