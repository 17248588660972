html {
  height: 100%;
}

body {
  height: 100%;
  font-family: "Open Sans", sans-serif !important;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

/**
* Global Styles
*/

* {
  box-sizing: border-box;
}

/**
* Global variables
* Access these as such: css-property: var(--variable-name);
*/

:root {
  /* Font Sizes */
  --font-size-1: 1rem;
  --font-size-2: 1.2rem;
  --font-size-3: 1.5rem;
  --font-size-4: 2.5rem;
  --font-size-5: 3rem;
  /* Font Colors */
  --font-color-1: #545454;
  --font-color-2: #898989;
  --font-color-3: #e2001a;
  --font-color-4: #005b9d;
  --font-color-5: #ffd100;
  /* Background Colors */
  --background-color-1: #ffffff;
  --background-color-2: #e2001a;
  --background-color-3: #005b9d;
  --background-color-4: #ffd100;
  --background-color-5: #fafafa;
  --background-color-6: #424242;
  /* Header */
  --header-height: 115px;
}

@media (min-width: 1500.99px) {
  .portal-container {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 1500px;
  }
}

/* MOBILE */
@media (max-width: 575.9px) {
  :root {
    --font-size-1: 0.75rem;
    --font-size-2: 1rem;
    --font-size-3: 1.2rem;
    --font-size-4: 2rem;
    --font-size-5: 2.5rem;
  }
}

/* SMALL MOBILE */
@media (max-width: 375px) {
  :root {
    --header-height: 90px;
  }
}

/* TABLET */
@media (min-width: 768px) and (max-width: 991.9px) {
}

/* DESKTOP */
@media (min-width: 1280px) and (max-width: 1919px) {
}

/**
* Libraries Custom CSS
*/
