.store {
  display: grid;
  grid-template-columns: 18em 1fr;
  grid-template-rows: 1fr;
  /*   column-gap: 3em */
  /*   margin: 0 2em 4em 2em; */
  height: 100%;
}

.store-fetching {
  display: grid;
  grid-template-columns: 18em 1fr;
  grid-template-rows: 1fr;
  height: 100%;
}

.store__filter {
  padding-right: 1em;
  overflow-y: auto;
  overflow-x: hidden;
}

.store__items-grid {
  grid-column: 2/5;
  grid-row: 1/5;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 20em);
  row-gap: 3em;
  overflow-y: auto;
  overflow-x: hidden;
}

.store__items-grid a {
  grid-column: span 1;
  grid-row: span 1;
  justify-self: center;
}

.store__items-grid a:hover {
  text-decoration: none;
}

@media (max-width: 1200px) {
  .store__items-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1000px) {
  .store,
  .store-fetching {
    grid-template-columns: 1fr;
    grid-template-rows: 10em 1fr 1fr 1fr;
  }

  .store__items-grid {
    grid-column: 1/3;
    grid-row: 2/5;
    column-gap: 5em;
  }

  .store__filter {
    padding-right: 0;
  }
}

/* TABLET */
@media (max-width: 768px) {
  .store,
  .store-fetching,
  .store__items-grid {
    grid-template-columns: 1fr;
  }
}
