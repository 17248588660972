.button {
  display: flex;
  display: block;
  border-radius: 3em;
  padding: 0.5em 1em;
  font-weight: bold;
  font-size: var(--font-size-2);
  color: white;
  border: none;
}

.button:focus {
  outline: none;
}

.button:hover {
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.4);
}

.button a {
  color: white;
}

.button a:hover {
  color: white;
  text-decoration: none;
}

.button--red {
  background-color: var(--background-color-2);
}

.button--blue {
  background-color: var(--background-color-3);
}

.button__arrow {
  width: 1.2em;
  margin-left: 0.8em;
}

/* MOBILE */
@media (max-width: 575px) {
}

/* TABLET */
@media (max-width: 1200px) {
}
