.contacts {
  display: flex;
}

.contacts__map {
  height: calc(100vh - var(--header-height));
  width: 50%;
  background-image: url(../../resources/media/images/contacts/Mapa.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.contacts__info {
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 4em;
  height: calc(100vh - var(--header-height));
}

.contacts__general-info {
  width: 100%;
}

.contacts__title {
  font-size: var(--font-size-4);
  color: var(--font-color-4);
  margin-bottom: 1em;
  font-weight: bold;
}

.contacts__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}

.contacts__social-group {
  display: flex;
  justify-content: space-between;
  width: 12em;
}

.contacts__social-icon {
  height: 1.5em;
  cursor: pointer;
}

.contacts__address-container {
  width: 100%;
}

.contacts__address {
  color: var(--font-color-1);
  width: 100%;
  border-bottom: 1px solid var(--font-color-1);
  margin-bottom: 1em;
}

.is-empty {
  display: none !important;
}

.contacts__address:last-child {
  border: none;
}

.contacts__address-header {
  display: flex;
  justify-content: space-between;
}

.contacts__address-title {
  font-size: var(--font-size-2);
  font-weight: bold;
}

.contacts__address-info {
  color: var(--font-color-2);
  font-weight: 400;
}

.contacts__address a {
  color: var(--font-color-4);
  font-weight: 500;
}
.contacts__address a:hover {
  text-decoration: underline;
}

.contacts__link-icon {
  width: 1em;
  margin-left: 0.5em;
  cursor: pointer;
}

.contacts__options {
  display: flex;
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin-bottom: 2em;
}

.contacts__options li {
  margin-right: 2em;
}

.contacts__options-btn {
  width: max-content;
  margin: 0.5em 0 0 0;
  padding: 0;
  border: none;
  font-size: var(--font-size-3);
  color: var(--font-color-1);
  background-color: var(--background-color-1);
  line-height: 2em;
}

.contacts__options-btn:focus {
  outline: none;
}

.contacts__options-btn--selected {
  font-weight: bold;
  color: var(--font-color-3);
  border-bottom: 4px solid var(--font-color-3);
}

@media (min-width: 1201px) {
  .contacts {
    position:fixed;
    width: 100%;
    max-width: 1500px;
  }
}

/* TABLET */
@media (max-width: 1200px) {
  .contacts {
    flex-direction: column-reverse;
  }

  .contacts__info {
    width: 100%;
    padding: 0 2em;
  }

  .contacts__map {
    width: 100%;
  }

  .contacts__options {
    margin: 3em 0;
  }

  .contacts__row {
    height: 10em;
    flex-direction: column;
    justify-content: space-around;
    margin: 0;
  }

  .contacts__general-info {
    text-align: center;
  }
}

/* MOBILE */
@media (max-width: 575px) {
  .contacts {
    margin-bottom: 3em;
  }

  .contacts__title {
    margin-bottom: 0.5em;
  }
  .contacts__address-header {
    flex-direction: column;
  }

  .contacts__address-header a {
    margin-bottom: 1em;
  }

  .contacts__row {
    height: 8em;
  }

  .contacts__map {
    display: none;
  }
}

.contacts__dropdown_div {
  width: 100%;
}

.contacts__dropdown {
  position: relative !important;
}