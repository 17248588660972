.search-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    height: 3em;
    margin-bottom: 1px;
  }
  
  .search-bar__input-line {
    width: 100%;
    border: none;
  }
  
  .search-bar__input-line:focus {
    outline: none;
  }
  
  .search-bar__search-icon {
    width: 1.5em;
    cursor: pointer;
  }
  