.project {
  color: var(--font-color-2);
}

.project__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: min-content 1fr;
  column-gap: 5em;
}

.project__image,
.project__image-border {
  width: 100%;
  height: 100%;
  margin: 0;
  grid-column-start: span 1;
  grid-row-start: span 3;
}

.project__image {
  position: absolute;
  object-fit: cover;
  top: 1em;
  right: 1em;
  z-index: 2;
}

.spacer {
  margin-top: calc(64px + 1em);
}

.project__image-border {
  position: relative;
  margin-left: 1em;
  z-index: 0;
}

.project__line {
  height: 0.25em;
  width: 2.5em;
  margin: 2em 0 0.5em 0;
}
/*Display the color according to project catgeory*/
.blue {
  background-color: var(--background-color-3);
}

.red {
  background-color: var(--background-color-2);
}

.yellow {
  background-color: var(--background-color-4);
}

.project__title {
  /* margin-top: 2em; */
  grid-row-start: span 1;
  height: min-content;
}

.project__title h1,
.project__service,
.project__label {
  color: var(--font-color-1);
  font-weight: bold;
}

.project__service-description,
.project__info-text {
  font-weight: 400;
}

.project__content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: max-content;
  margin-top: 2em;
  grid-row-start: span 1;
}

.project__info {
  display: flex;
  justify-content: space-around;
  text-align: center;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding: 2em 0;
  margin-top: 2em;
}

.project__long-description {
  margin-top: 5em;
}

.project__button-container {
  display: flex;
  justify-content: center;
  margin: 4em 0;
  padding-bottom: 4em;
}

.project__button-container a {
  text-decoration: none !important;
}

.grid {
  z-index: 0;
  margin-top: 5em;
}

.grid a:hover {
  text-decoration: none;
}

.image__container {
  position: relative;
}

.image__container:hover .image__content {
  box-shadow: 5px 4px 11px -1px #757575;
}

.image__content {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: contain;
}

.image__play-icon {
  position: absolute;
  width: 3em;
  right: 1em;
  bottom: 1em;
  cursor: pointer;
}

/* DESKTOP */
@media (max-width: 1500px) {
}

/* TABLET */
@media (max-width: 1200px) {
  .project__short-description-text {
    margin-top: 2em;
  }

  .spacer {
    margin-top: unset;
  }

  .project {
    margin: 0 1em;
  }

  .project__grid {
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content 1fr;
  }

  .project__image,
  .project__image-border {
    width: 100%;
    margin: 0 auto;
    grid-row: 2/3;
    height: 70vh;
  }

  .project__image-border {
    margin: 0 1em;
  }

  .project__title {
    margin-bottom: 1em;
  }

  .project__content {
    grid-row-start: span 1;
  }

  .project__info {
    margin: 2em 0;
  }

  .project__long-description {
    margin-top: 0;
  }
}

/* MOBILE */
@media (max-width: 575px) {
}
