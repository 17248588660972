.accordion {
  box-shadow: none !important;
  margin-bottom: 1px;
}

.accordion::before {
  content: none !important;
}

fieldset[class*="MuiFormControl-root"] {
  width: 100%;
}

.accordion__summary {
  box-shadow: 0px 2px 1px -1px grey;
  padding: 1px 2px !important;
}

.accordion__details {
  color: #898989;
  padding: 1px 2px !important;
}

.accordion__details label:first-of-type {
  margin-top: 1em;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: unset !important;
}

.form-group img {
  margin: 1em;
  width: 100%;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

div[class*="Mui-expanded"] {
  margin: 0 !important;
  min-height: unset !important;
}

div[class*="MuiAccordionSummary-expandIcon"] {
  margin-right: -12px !important;
}
