.homepage {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.homepage div[class*="carousel slide"] {
  margin-top: var(--header-height) !important;
  height: calc(100% - var(--header-height)) !important ;
}
.homepage div[class*="carousel-inner"],
.homepage div[class*="carousel-item"] {
  height: 100% !important;
}

.homepage div[class*="active"] {
  display: flex;
}

.homepage__carousel-arrow {
  height: 3em;
  width: auto;
}

.homepage a[class*="carousel-control-next"] {
  justify-content: flex-end;
  margin-right: 1em;
}

.homepage a[class*="carousel-control-prev"] {
  justify-content: flex-start;
  margin-left: 1em;
}

.homepage ol[class*="carousel-indicators"] {
  bottom: 1em;
  justify-content: unset;
  margin-left: 1em;
  margin-right: unset;
  opacity: 50% !important;
}

.homepage ol[class*="carousel-indicators"] li {
  mask: url("../../resources/media/images/homepage/circle_unselected.svg") no-repeat 50% 50%;
  background-color: black;
  opacity: 50% !important;
  height: 1em;
  width: 1em;
  border: unset;
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45),
    background-color 0.175s ease-out;
}

.homepage ol[class*="carousel-indicators"] li[class*="active"] {
  mask: url("../../resources/media/images/homepage/circle_selected.svg") no-repeat 50% 50%;

  transform: scale(1.5);
}

.homepage__carousel-video {
  background-color: #333333;
}

.homepage__carousel-video,
.homepage__carousel-image {
  object-fit: contain;
  width: 100vw;
  height: 100%;
}

.homepage__carousel-video__buttons {
  position: absolute;
  z-index: 20;
  bottom: 1em;
  right: 0;
  margin-right: 1em;
}

.homepage__carousel-video__button {
  background: none;
  border: none;
  padding: 0;
  height: 4em;
  width: 4em;
  margin-left: 1em;
}

.homepage__carousel-video__button:focus {
  outline: none;
}

.homepage__carousel-image {
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homepage__carousel-slide__body {
  bottom: 20% !important;
  text-align: initial !important;
}