.product__return-link {
  color: var(--font-color-1);
}

.product__return-link:hover {
  text-decoration: none;
  color: var(--font-color-1);
  font-weight: 600;
}

.product__return-arrow {
  width: 1.5em;
  margin: 0 0.5em 0 1em;
}

.product {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, min-content);
  column-gap: 3em;
  color: var(--font-color-1);
  margin: 0 2em;
}

.product__images-carousel {
  grid-column: 1/2;
  grid-row: 1/3;
  justify-self: center;
  position: relative;
}

.product__header {
  grid-column: 2/3;
  grid-row: 1/2;
  margin-top: 2em;
}

.product__header h3 {
  font-weight: 700;
}

.product__prices {
  display: flex;
  justify-content: space-between;
  width: 13em;
  margin: 1.5em 0;
}

.product__price-now h5,
.product__price-now h4 {
  font-weight: bold;
  color: var(--font-color-3);
}

.product__value-before {
  text-decoration: line-through;
}

.product__price-before h5,
.product__price-before h4 {
  font-weight: 400;
  color: var(--font-color-2);
}

.product__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-row: 2/3;
  grid-column: 2/3;
}

.product__info h6:first-of-type {
  font-weight: bold;
}

.product__features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 2em 0;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
}

.product__single-feature {
  text-align: center;
  width: 15%;
  min-width: 6em;
  margin: 1em 0;
}

.product__single-feature img {
  width: 3em;
  margin-bottom: 2em;
}

.product__colors {
  border-bottom: 1px solid grey;
  padding: 2.5em 0;
  margin-bottom: 2em;
}

.product__colors h5 {
  margin-bottom: 1em;
  font-weight: bold;
}

.product__colors-container {
  display: flex;
  flex-wrap: wrap;
}

.product__color-rectangle {
  display: flex;
  align-items: center;
  padding: 0.8em 1em;
  border: 1px solid rgb(194, 194, 194);
  margin-right: 1em;
  cursor: pointer;
}

.product__color-rectangle--selected {
  border: 1px solid var(--background-color-2);
  box-shadow: 0px 0px 1px 1px rgba(255, 0, 0, 0.5);
}

.product__color-rectangle h6 {
  margin: 0;
}

.product__color-rectangle--selected h6 {
  font-weight: bold;
}

.product__color-circle {
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  margin-right: 1em;
  border: 1px solid grey;
}

@media (max-width: 1200px) {
  .product {
    height: min-content;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
  }

  .product__header {
    grid-column: span 1;
    grid-row: 1/2;
  }

  .product__images-carousel {
    grid-column: span 1;
    grid-row: 2/3;
    margin-bottom: 3em;
  }

  .product__info {
    grid-column: span 1;
    grid-row: 3/4;
  }
}

/* TABLET */
@media (max-width: 1200px) {
}

/* MOBILE */
@media (max-width: 575px) {
  .product__single-feature {
    width: 50%;
  }

  .product__color-rectangle {
    width: 14em;
    margin: 0.5em;
  }
}
