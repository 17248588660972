.contact-form {
  color: var(--font-color-1);
  display: flex;
  flex-direction: column;
}

.terms-and-conditions {
  position: absolute;
  padding: 3em 5em;
  background-color: rgb(252, 252, 252);
  height: fit-content;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@media (max-width: 849.99px) {
  .terms-and-conditions {
    padding: 3em 2em;
  }
}

.return-arrow {
  width: 1.5em;
  margin-bottom: 2em;
  cursor: pointer;
}
.contact-form h2 {
  color: var(--font-color-4);
  font-weight: bold;
}

.contact-form__input-field {
  display: block;
  margin: 2em 0;
  font-weight: 500;
}

.contact-form__input-field input {
  width: 100%;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid black;
  border-radius: 0;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: rgb(165, 165, 165);
}

.contact-form input:focus {
  outline: none;
  border-bottom: 2px solid var(--background-color-3);
}

.contact-form__input-field textarea {
  width: 100%;
  padding: 0.5em;
  background-color: rgb(252, 252, 252);
  height: 8em;
  border-radius: 0;
}

.contact-form__input-field textarea:focus {
  outline: none;
  border: 2px solid var(--background-color-3);
}

.contact-form__error-message {
  font-weight: 400;
  font-size: 0.9rem;
  margin: 0.5em 0;
  color: red;
}

.contact-form button {
  float: right;
  border-radius: 2em;
  padding: 0.4em 2em;
  margin-top: 1.5em;
  color: white;
  background-color: var(--background-color-3);
  border: none;
  font-weight: bold;
}

.contact-form button:focus {
  outline: none;
}

.contact-form__checkbox {
  display: flex;
  align-items: center;
}

.contact-form__checkbox h6 {
  margin: 0 0.5em;
  font-weight: 400;
  font-size: 0.9rem;
}

.contact-form__checkbox input[type="checkbox"] {
  width: 1.5em;
  height: 1em;
}

.contact-form__checkbox span {
  text-decoration: underline;
  color: var(--background-color-3);
  cursor: pointer;
}
.contact-form__checkbox span:hover {
  font-weight: 600;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(252, 252, 252) inset !important;
}
