.careers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: calc(100vh - var(--header-height));
}

.careers__image {
  width: 45em;
  height: 100%;
  object-fit: cover;
  clip-path: circle(65% at 18% 45%);
}

.careers__text {
  margin: 10em 3em 0 0;
}

.careers__question {
  color: var(--font-color-4);
  font-size: var(--font-size-4);
}

.careers__info {
  color: var(--font-color-1);
  font-weight: 400;
  margin-bottom: 2em;
  line-height: 1.5em;
}

@media (min-height: 1170px) {
  .careers__image {
    width: 50em;
  }
  .careers__text {
    margin: 10em 1em 0 2em;
  }
}
@media (min-width: 1201px) {
  .careers {
    position: fixed;
    max-width: 1500px;
  }
}
/* TABLET */
@media (max-width: 1200px) {
  .careers {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 100%;
    margin-right: 0;
    padding: 0 1em;
  }

  .careers__image {
    width: 100%;
    grid-row: 2/3;
    clip-path: none;
    margin-top: 2em;
  }
  .careers__text {
    margin-top: 4em;
    text-align: center;
  }
  .careers__button {
    display: flex;
    justify-content: center;
  }
}

/* MOBILE */
@media (max-width: 575px) {
  .careers__text {
    margin: 0;
  }
}
