.popup__background {
  position: fixed;
  top: var(--header-height);
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
}

.popup__close-icon {
  position: absolute;
  width: 1.8em;
  top: 1.5em;
  right: 1.5em;
  cursor: pointer;
  z-index: 4;
}

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: var(--header-height);
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
}

a[class*="carousel-control-prev"],
a[class*="carousel-control-next"] {
  height: calc(100% - 5em);
  padding-top: 5em;
}

.popup__media {
  position: relative;
  width: 80vw;
  height: 80vh;
  object-fit: contain;
}

/* DESKTOP */
@media (max-width: 1500px) {
}

/* TABLET */
@media (max-width: 1200px) {
}

/* MOBILE */
@media (max-width: 575px) {
  .popup__close-icon {
    top: 1em;
    right: 1em;
  }
}
