.accordion-item__filter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  padding: 0;
  background-color: white;
  color: var(--font-color-1);
  font-weight: 500;
  margin-bottom: 1em;
  border-bottom: 1px solid var(--font-color-2);
}

.accordion-item__filter:focus {
  outline: none;
}

.accordion-item__arrow-icon {
  width: 0.8em;
  transition: transform 0.3s linear;
}

.accordion-item__arrow-icon--inverse {
  transform: scaleY(-1);
  transition: transform 0.3s linear;
}

.children-container {
  width: 100%;
  visibility: hidden;
  position: absolute;
  opacity: 0;
  display: none;
}

.children-container--show {
  visibility: visible;
  opacity: 1;
  position: static;
  display: block;
  animation: fade 0.6s;
}

@keyframes fade {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
