.section {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--header-height));
  min-height: 100vh;
}

.first-section,
.third-section {
  flex-direction: row;
}

.first-section__image,
.third-section__image {
  width: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: ellipse(100% 76% at 0% 74%);
  background-image: url(../../../resources/media/images/services/team-work.jpg);
}

.first-section__text,
.third-section__text {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  padding: 2rem 6rem;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.card {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 33.33%;
  background: rgba(137, 137, 137, 1);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
  color: white;
}
.card img {
  height: 15em;
  width: 100%;
  object-fit: cover;
}

.card__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  text-align: center;
}

.card__content-title {
  font-weight: bold;
}

.third-section__image {
  background-image: url(../../../resources/media/images/services/code.png);
}
.third-section__text h1 {
  margin-bottom: 1em;
}

@media (max-width: 1200px) {
  .first-section,
  .third-section {
    height: unset;
    min-height: 0;
    padding: 50px 0;
  }

  .first-section__image,
  .third-section__image {
    display: none;
  }

  .first-section__text,
  .third-section__text {
    width: 100%;
    padding: 2em 1em;
  }
}

@media (max-width: 849.99px) {
  .card {
    min-width: 50%;
  }
}

@media (max-width: 584.99px) {
  .card {
    min-width: 100%;
  }
}
