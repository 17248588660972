/*TABLE*/
.product__detailed-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 3em;
  row-gap: 3em;
  margin: 3em 2em;
  color: var(--font-color-1);
}

.product__detailed-info td,
.product__detailed-info th {
  text-align: left;
  padding: 0.5em;
  width: 50vw;
}

.product__general-info {
  grid-column: 1/2;
  grid-row: 1/2;
}
.product__screen-info {
  grid-column: 2/3;
  grid-row: 1/2;
}

.product__connectivity-info {
  grid-column: 1/2;
  grid-row: 2/3;
}

.product__physical-info {
  grid-column: 2/3;
  grid-row: 2/3;
}

@media (max-width: 1200px) {
  .product__detailed-info {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, min-content);
  }

  .product__general-info {
    grid-column: span 1;
    grid-row: 1/2;
  }
  .product__screen-info {
    grid-column: span 1;
    grid-row: 2/3;
  }

  .product__connectivity-info {
    grid-column: span 1;
    grid-row: 3/4;
  }

  .product__physical-info {
    grid-column: span 1;
    grid-row: 4/5;
  }
}
