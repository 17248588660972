.popup-container {
  position: fixed;
  left: 0;
  top: var(--header-height);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  width: 100vw;
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em 0;
}

.popup-container__content {
  position: relative;
  width: 66em;
  height: min-content;
  background-color: var(--background-color-5);
  padding: 3em 5em;
  overflow: auto;
}

.close-button {
  position: absolute;
  right: 1.8em;
  top: 2em;
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .popup-container {
    padding: 0;
  }

  .popup-container__content {
    position: relative;
    width: 100vw;
    height: calc(100vh - var(--header-height));
    padding: 5em 3em;
  }

  .close-button img {
    margin: 1em 0;
    width: auto;
    height: 100%;
  }

  .close-button {
    display: flex;
    position: absolute;
    top: 1em;
    cursor: pointer;
  }
}

@media (max-width: 575px) {
  .popup-container__content {
    padding: 5em 1em;
  }
}
