.toolbar__search-icon {
    width: 1.5em;
    margin-left: 1em;
}

.toolbar__search-button {
    width: 1.5em;
    height: 1.5em;
    padding: 0 !important;
    margin: 0.5em !important;
}

