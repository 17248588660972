.horizontal-menu__options {
  position: fixed;
  justify-content: space-evenly;
  list-style-type: none;
  padding: 0 0 3em 0;
  width: inherit;
  top: var(--header-height);
  height: 10em;
  background-color: var(--background-color-1);
  display: grid;
  grid-auto-flow: column;
  column-gap: 1.5em;
  align-items: center;
  align-content: flex-end;
}

.horizontal-menu__options-btn {
  padding: 0;
  border: none;
  font-size: var(--font-size-2);
  color: var(--font-color-1);
  background-color: var(--background-color-1);
  line-height: normal;
}

.horizontal-menu__options-btn:focus {
  outline: none;
}

.horizontal-menu__options-btn--selected {
  font-weight: bold;
  color: var(--font-color-3);
  border-bottom: 4px solid var(--font-color-3);
}
