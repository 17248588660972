.dropdown-menu {
  position: absolute;
  background-color: white;
  min-width: 100%;
  border: 0.5px solid var(--background-color-6);
  z-index: 2;
  padding: 0.3em 1em;
}

.dropdown-menu__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 0.5em 0;
  border: none;
  background-color: white;
  color: var(--font-color-1);
  font-weight: 600;
}

.dropdown-menu__button:focus {
  outline: none;
}

.dropdown-menu__arrow-icon {
  width: 1em;
  margin-left: 1em;
}

.dropdown-menu__arrow-icon--inverse {
  transform: scaleY(-1);
}

.dropdown-menu__options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.8em 0;
  list-style-type: none;
  border-radius: 0.2em;
  margin-bottom: 0;
  line-height: 1.5;
}

.dropdown-menu__options li {
  cursor: pointer;
  padding: 0.5em 0;
  margin: 0;
}

.dropdown-menu__options li:hover {
  font-weight: 600;
  color: var(--font-color-1);
}

.dropdown-menu__options--border-top {
  border-top: 2px solid var(--font-color-3);
}

.dropdown-menu__options--hidden {
  display: none;
}
